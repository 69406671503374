var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Alert", {
    attrs: {
      title: _vm.$t("MenuItemsSettings.confirm_MatchCopyTitle"),
      description: _vm.$t("MenuItemsSettings.confirm_MatchCopyDescription", {
        baseName: this.$route.params.baseName,
        releatedName: this.$route.params.releatedName,
        baseRvcName: this.$route.params.baseRvcName,
        releatedRvcName: this.$route.params.releatedRvcName,
      }),
      okButton: _vm.$t("MenuItemsSettings.confirm_MatchCopyCopyButton"),
      icon: "icon-global-notify",
    },
    on: { delegateOnAlertOk: _vm.itemCopy, delegateOnAlertCancel: _vm.close },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }