<template lang="pug">
Alert(
        :title                   ="$t('MenuItemsSettings.confirm_MatchCopyTitle')"
        :description             ="$t('MenuItemsSettings.confirm_MatchCopyDescription', {baseName: this.$route.params.baseName, releatedName: this.$route.params.releatedName, baseRvcName: this.$route.params.baseRvcName, releatedRvcName: this.$route.params.releatedRvcName})"
        :okButton                ="$t('MenuItemsSettings.confirm_MatchCopyCopyButton')"
        icon                     ="icon-global-notify"
        @delegateOnAlertOk       ="itemCopy"
        @delegateOnAlertCancel   ="close"
    )
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TSPDetailRemove',

  data () {
    return {
    }
  },

  async mounted () {
    this.item = this.$route.params.item
  },

  computed: {
    itemWithFormattedDate () {
      const despatchDateFormatted = new Date(this.item.despatchDate).toLocaleDateString('tr')
      return Object.assign({}, this.item, {
        despatchDateFormatted
      })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('MenuItemsSettings', ['postMatchCopy']),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemCopy () {
      const params = {
        sourceRevenueCenterId: this.$route.params.baseRvcId,
        destinationRevenueCenterId: this.$route.params.releatedRvcId
      }
      this.postMatchCopy(params).then((res) => {
        if (!res) return
        const message = this.$t('MenuItemsSettings.success_matched_copy_message')
        this.notifyShow({ message })
        this.close()
        this.$emit('defualtSet')
      })
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
