var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Alert", {
    attrs: {
      icon: "icon-notify-close",
      item: _vm.item,
      fields: _vm.fields,
      tableName: this.$t("MenuItemsSettings.remove_Matched"),
      okButton: this.$t("MenuItemsSettings.ok_Button_Matched"),
      cancelButton: this.$t("MenuItemsSettings.cancel_Button_Matched"),
      loading: "ListsTransferSalesPrice/deleteTransferSalesPriceDetailList",
    },
    on: { delegateOnAlertOk: _vm.itemRemove, delegateOnAlertCancel: _vm.close },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }