export default function getFields($t) {
  return [
    {
      name: 'storeGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_storeGroupName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_storeGroupName')
      }
    },
    {
      name: 'sourceIntegrationProductName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceIntegrationProductName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceIntegrationProductName')
      }
    },
    {
      name: 'sourceRevenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceRevenueCenterName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceRevenueCenterName')
      }
    },
    {
      name: 'sourceMenuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceMenuItemGroupName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceMenuItemGroupName')
      }
    },
    {
      name: 'sourceMenuItemName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceMenuItemName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_sourceMenuItemName')
      }
    },
    {
      name: 'destinationIntegrationProductName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationIntegrationProductName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationIntegrationProductName')
      }
    },
    {
      name: 'destinationRevenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationRevenueCenterName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationRevenueCenterName')
      }
    },
    {
      name: 'destinationMenuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationMenuItemGroupName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationMenuItemGroupName')
      }
    },
    {
      name: 'destinationMenuItemName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationMenuItemName')
      },
      get title() {
        return $t('MenuItemsSettings.matched_tableColumn_destinationMenuItemName')
      }
    },
    {
      name: 'partition',
      type: 'portal',
      is_visible: true
    }
  ]
}
