export function getOldMenuItemsField($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_SalesItems')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_SalesItems')
      }
    },
    {
      name: 'menuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_MenuItemGroup')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_MenuItemGroup')
      }
    },
    {
      name: 'revenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_RVC')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_RVC')
      }
    }
  ]
}

export function getNewMenuItemsField($t) {
  return [
    {
      name: 'destinationMenuItemId',
      type: 'portal',
      style: 'width: 1%',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_SalesItems')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_SalesItems')
      }
    },
    {
      name: 'menuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_MenuItemGroup')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_MenuItemGroup')
      }
    },
    {
      name: 'revenueCenterName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_RVC')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_RVC')
      }
    }
  ]
}
