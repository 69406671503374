import MenuItemsSettings from '../index'
import MatchCopy from '../matchcopy'
import MatchedList from '../matchedlist'
import TargetStore from '../targetstore'
import matchCopyAlert from '../popup/match-copy-alert'
import matchRemoveAlert from '../popup/match-remove-alert'
import targetStoreEdit from '../popup/target-store-edit'

export default [
  {
    name: 'MenuItemsSettings',
    path: '/menuitemsconfig',
    component: MenuItemsSettings,
    meta: {
      main_menu: true,
      slug: 'menuitemsconfig',
      page: 'menuitemsconfig',
      category: 'Governance',
      permissionKey: 'MenuItemSettingsMenu'
    }
  },
  {
    name: 'MatchedList',
    path: '/menuitemsconfig/matchedlist',
    component: MatchedList,
    meta: {
      slug: 'menuitemsconfig',
      page: 'menuitemsconfig',
      permissionKey: 'MenuItemSettingsMenu'
    },
    children: [
      {
        name: 'MatchRemoveAlert',
        path: '/menuitemsconfig/matchedlist/remove/:id',
        component: matchRemoveAlert
      }
    ]
  },
  {
    name: 'MatchCopy',
    path: '/menuitemsconfig/matchcopy',
    component: MatchCopy,
    meta: {
      slug: 'menuitemsconfig',
      page: 'menuitemsconfig'
    },
    children: [
      {
        name: 'MatchCopyAlert',
        path: '/menuitemsconfig/matchcopy/:id/copy/:releatedId',
        component: matchCopyAlert
      }
    ]
  },
  {
    name: 'TargetStore',
    path: '/menuitemsconfig/targetstore',
    component: TargetStore,
    meta: {
      slug: 'menuitemsconfig',
      page: 'menuitemsconfig'
    },
    children: [
      {
        name: 'TargetStoreEdit',
        path: '/menuitemsconfig/targetstore/edit',
        component: targetStoreEdit
      }
    ]
  }
]
