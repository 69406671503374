import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    avaibleStoreGroupList: [],
    newMenuItemList: [],
    oldMenuItemList: [],
    oldListPagination: {
      ...Page
    },
    newListPagination: {
      ...Page
    },
    matchedListPagination: {
      ...Page
    },
    pageHeaderLinks: [
      'MenuItemsSettings',
      'MatchedList',
      'MatchCopy',
      'TargetStore'
    ]
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    avaibleStoreGroupList: state => state.avaibleStoreGroupList,
    newMenuItemList: state => state.newMenuItemList,
    oldMenuItemList: state => state.oldMenuItemList,
    newListPagination: state => PageGetters({ state, stateName: 'newListPagination' }),
    oldListPagination: state => PageGetters({ state, stateName: 'oldListPagination' }),
    matchedListPagination: state => PageGetters({ state, stateName: 'matchedListPagination' })
  },

  actions: {

    async fetchAvabileStoreGroups({ commit }) {
      const results = await requestHandler({
        title: 'fetchAvabileStoreGroups',
        methodName: 'fetchAvabileStoreGroups',
        method: () => {
          return HTTP.get('pos/menuitemmapping/storegroups')
        },
        success: result => {
          commit('SET_AVAIBLE_STORE_GROUP_LIST', result.data.storeGroups)
          return result
        }
      })
      return results
    },

    async fetchStoreGroupIntegrationInfo({ commit }, params) {
      const results = await requestHandler({
        title: 'fetchStoreGroupIntegrationInfo',
        methodName: 'fetchStoreGroupIntegrationInfo',
        method: () => {
          return HTTP.get('pos/menuitemmapping/filterinfo', {
            params: { ...params }
          })
        },
        success: result => result
      })
      return results
    },

    async matchedMenuItemsForIntegration({ commit }, params) {
      const results = await requestHandler({
        title: 'matchedMenuItemsForIntegration',
        methodName: 'matchedMenuItemsForIntegration',
        method: () => {
          return HTTP.post('pos/menuitemmapping/map', params)
        },
        success: result => result
      })
      return results
    },

    async postMatchCopy({ commit }, params) {
      const results = await requestHandler({
        title: 'postMatchCopy',
        methodName: 'postMatchCopy',
        method: () => {
          return HTTP.post('pos/menuitemmapping/copy', params)
        },
        success: result => result
      })
      return results
    },

    async fetchMenuItemList({ state, commit }, obj) {
      const isOldIntegration = obj.type === 'old'
      const payload = obj.payload
      const currentPage = isOldIntegration ? state.oldListPagination.number : state.newListPagination.number
      // const currentPageSize = isOldIntegration ? state.oldListPagination.size : state.newListPagination.size
      const currentPageSize = obj.payload.pageSize
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage })

      const params = {
        ...payload,
        pageSize: currentPageSize,
        pageNumber: PageNumber || currentPage
      }

      const results = await requestHandler({
        title: 'fetchMenuItemList',
        methodName: 'fetchMenuItemList',
        method: () => {
          return HTTP.post('pos/menuitemmapping/filter', params)
        },
        success: result => {
          if (isOldIntegration) {
            commit('SET_OLD_MENU_ITEMS_LIST', result.data)
          } else {
            commit('SET_NEW_MENU_ITEMS_LIST', result.data)
          }
          return result
        }
      })
      return results
    },

    async getStoreGroupRevenueCentersList({ commit }, params) {
      const results = await requestHandler({
        title: 'getStoreGroupRevenueCentersList',
        methodName: 'getStoreGroupRevenueCentersList',
        method: () => {
          return HTTP.post('pos/menuitemmapping/revenuecenters/source', params)
        },
        success: result => result
      })
      return results
    },

    async getMenuItemsGroup({ commit }, params) {
      const results = await requestHandler({
        title: 'getMenuItemsGroup',
        methodName: 'getMenuItemsGroup',
        method: () => {
          return HTTP.post('pos/menuitemmapping/menuitemgroups/source', params)
        },
        success: result => result
      })
      return results
    },

    async getMappingAll({ state, commit }, params) {
      params.pageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.matchedListPagination.number })
      params.pageSize = state.matchedListPagination.size
      const results = await requestHandler({
        title: 'getMappingAll',
        methodName: 'getMappingAll',
        method: () => {
          return HTTP.post('pos/menuitemmapping/map/all', params)
        },
        success: result => {
          commit('SET_MATCHED_LIST_PAGINATION', result.data)
          return result
        }
      })
      return results
    },

    async removeMappingItem({ commit }, id) {
      const results = await requestHandler({
        title: 'removeMappingItem',
        methodName: 'removeMappingItem',
        method: () => {
          return HTTP.delete('pos/menuitemmapping/map', {
            params: { id }
          })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_AVAIBLE_STORE_GROUP_LIST(state, list) {
      state.avaibleStoreGroupList = list
    },

    SET_NEW_MENU_ITEMS_LIST(state, data) {
      state.newMenuItemList = data.menuItems
      state.newListPagination.number = data.pageNumber
      state.newListPagination.size = data.pageSize
      state.newListPagination.total = data.totalCount
    },

    SET_OLD_MENU_ITEMS_LIST(state, data) {
      state.oldMenuItemList = data.menuItems
      state.oldListPagination.number = data.pageNumber
      state.oldListPagination.size = data.pageSize
      state.oldListPagination.total = data.totalCount
    },

    SET_MATCHED_LIST_PAGINATION(state, data) {
      state.matchedListPagination.number = data.pageNumber
      state.matchedListPagination.total = data.totalCount
    },

    RESET_MENU_ITEMS_SETTINGS_STATE(state) {
      state.newMenuItemList = []
      state.oldMenuItemList = []
      state.oldListPagination = {
        ...Page
      }
      state.newListPagination = {
        ...Page
      }
    }
  }
}
