<template lang="pug">
Alert(
    :icon="'icon-notify-close'"
    :item="item",
    :fields="fields",
    :tableName="this.$t('MenuItemsSettings.remove_Matched')",
    :okButton="this.$t('MenuItemsSettings.ok_Button_Matched')"
    :cancelButton="this.$t('MenuItemsSettings.cancel_Button_Matched')"
    loading="ListsTransferSalesPrice/deleteTransferSalesPriceDetailList",
    @delegateOnAlertOk="itemRemove"
    @delegateOnAlertCancel="close")
</template>

<script>
import { mapActions } from 'vuex'
import getFields from '../fields/matched'

export default {
  name: 'MatcedRemove',

  data () {
    return {
      fields: getFields(this.$t),
      item: {}
    }
  },

  async mounted () {
    if (!this.$route.params.item) this.close()
    this.item = this.$route.params.item
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('MenuItemsSettings', [
      'removeMappingItem'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    async itemRemove () {
      let result = await this.removeMappingItem(this.item.id)
      if (result.status === 200) {
        const message = this.$t('MenuItemsSettings.removeMatched_Notificiation_Deleted')
        this.notifyShow({ message })
        this.$emit('getList')
        this.close()
      }
    }

  }
}
</script>

<style lang="scss">
</style>
