export function targetStoreFields($t) {
  return [
    {
      name: 'menuItemGroupName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_menuItemGroupName')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_menuItemGroupName')
      }
    },
    {
      name: 'menuItemName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_menuItemName')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_menuItemName')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('MenuItemsSettings.table_Column_storeName')
      },
      get title() {
        return $t('MenuItemsSettings.table_Column_storeName')
      }
    }
  ]
}
