import BaseClass from '@/utils/baseclass'

export default class TargetStores extends BaseClass {
  addItem(item) {
    let newItem = new TargetStore(item)
    this.list.push(newItem)
  }
}

class TargetStore {
  constructor(item) {
    this.menuItemGroupId = item.menuItemGroupId
    this.menuItemGroupName = item.menuItemGroupName
    this.menuItemId = item.menuItemId
    this.menuItemName = item.menuItemName
    this.storeId = item.storeId
    this.storeName = item.storeName
  }
}
