<template lang="pug">
Popup
    template(slot="title")
      template {{ $t('MenuItemsSettings.target_store_edit') }}
    template(slot="content")
      form.Form(
        id="target-store-edit-form"
        @submit.prevent="onSubmitForm"
      )
        itemInfoShowcase(
          :listData="showCaseData"
        )
        .Form-item.required
          label.Form-item-label(:id="`target-store-popup-form-label-store-name`")
            | {{ $t('Dictionary.Store') }}
          .control.form-item-select
            custom-search(
              ref="secondaryInput"
              component-name="target-store-popup-store-select"
              name="storeName",
              v-model="form.store",
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StoreName')",
              v-validate="'required|is_not:-1'"
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="searchStoreList",
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :placeholder="$t('Despatches.detail_Popup_FormField_StoreName_Placeholder')"
              :loading="isSearchStoreLists"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="medium",
        type="submit"
        variant="full"
        id="button-target-store-edit",
        form="target-store-edit-form",
        :disabled="isCreating",
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template {{ $t('MenuItemsSettings.target_store_submit_button') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import itemInfoShowcase from '@/view/global/item-info-showcase'

export default {
  name: 'target-store-edit',

  data () {
    return {
      form: {
        store: {
          id: null,
          name: null
        }
      }
    }
  },

  components: {
    itemInfoShowcase
  },

  props: {
    selectedData: {
      type: Array,
      default: () => []
    },
    selectedStore: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('Stores', ['searchStoreList']),
    ...mapGetters('Settings', ['UserInfo']),

    storeListItems () {
      return this.storeList.filter(i => i.type === 1)
    },

    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    showCaseData () {
      const menuItemGroupNames = this.selectedData.map(i => i.menuItemGroupName)
      const menuItemName = this.selectedData.map(i => i.menuItemName)
      const finalData = [
        {
          title: this.$t('MenuItemsSettings.table_Column_menuItemGroupName'),
          list: menuItemGroupNames
        },
        {
          title: this.$t('MenuItemsSettings.table_Column_menuItemName'),
          list: menuItemName
        }
      ]
      return finalData
    },

    isCreating () {
      return this.$wait.is(['targetStoreEdit'])
    }
  },

  methods: {
    ...mapActions('Stores', ['fetchStoreByName']),

    ...mapActions('MenuItems', ['editTargetStores']),

    ...mapActions('Notify', ['notifyShow']),

    async getOptionsStore (search) {
      await this.fetchStoreByName({name: search})
    },

    inputSelection (data) {
      if (data.length !== 0) {
        this.form.store.id = data.id
        this.item.store.name = data.name
      }
    },

    resetOptions () {
      this.getOptionsStore(null)
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      const payload = {
        menuItemIds: this.selectedData.map(i => i.menuItemId),
        storeId: this.form.store.id
      }

      await this.editTargetStores(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('MenuItemsSettings.target_store_update_message')
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        })
    }, 'targetStoreEdit')
  },

  async mounted () {
    if (this.selectedData.length === 0) {
      this.close()
      return
    }
    await this.getOptionsStore('')
    const store = this.searchStoreList.find(i => i.id === this.selectedStore)
    this.form.store = {
      id: store.id,
      name: store.name
    }
  }

}
</script>

<style scoped lang="scss">

</style>
