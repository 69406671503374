var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [[_vm._v(_vm._s(_vm.$t("MenuItemsSettings.target_store_edit")))]],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "target-store-edit-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("itemInfoShowcase", { attrs: { listData: _vm.showCaseData } }),
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "label",
                {
                  staticClass: "Form-item-label",
                  attrs: { id: `target-store-popup-form-label-store-name` },
                },
                [_vm._v(_vm._s(_vm.$t("Dictionary.Store")))]
              ),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    ref: "secondaryInput",
                    attrs: {
                      "component-name": "target-store-popup-store-select",
                      name: "storeName",
                      "data-vv-as": _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName"
                      ),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeName"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.searchStoreList,
                      label: "name",
                      searchAll: true,
                      withDebounce: false,
                      placeholder: _vm.$t(
                        "Despatches.detail_Popup_FormField_StoreName_Placeholder"
                      ),
                      loading: _vm.isSearchStoreLists,
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.form.store,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "store", $$v)
                      },
                      expression: "form.store",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "medium",
                type: "submit",
                variant: "full",
                id: "button-target-store-edit",
                form: "target-store-edit-form",
                disabled: _vm.isCreating,
                isLoading: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("MenuItemsSettings.target_store_submit_button")
                      )
                    ),
                  ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }